// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\Gianp\\Documents\\React\\lucapapa\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-js": () => import("C:\\Users\\Gianp\\Documents\\React\\lucapapa\\src\\templates\\index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-post-js": () => import("C:\\Users\\Gianp\\Documents\\React\\lucapapa\\src\\templates\\post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("C:\\Users\\Gianp\\Documents\\React\\lucapapa\\src\\templates\\tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-page-js": () => import("C:\\Users\\Gianp\\Documents\\React\\lucapapa\\src\\templates\\page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\Gianp\\Documents\\React\\lucapapa\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("C:\\Users\\Gianp\\Documents\\React\\lucapapa\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\Gianp\\Documents\\React\\lucapapa\\.cache\\data.json")

